document.addEventListener('DOMContentLoaded', function() {

  // Mobile menu
  const mobileMenuButton = document.getElementById('open-menu-btn');
  const mobileMenu = document.getElementById('mobile-menu');

  const menuLinks = document.querySelectorAll('#mobile-menu a[href]');
  menuLinks.forEach(link => {
    link.addEventListener('click', function() {
      mobileMenu.classList.toggle('hidden');
    });
  })

  mobileMenuButton.addEventListener('click', function() {
    mobileMenu.classList.toggle('hidden');
  });

  const closeMenuButton = document.getElementById('close-menu-btn');
  closeMenuButton.addEventListener('click', function() {
      const mobileMenu = document.getElementById('mobile-menu');
      mobileMenu.classList.add('hidden');
  });



  // Get all question buttons and their corresponding content
  const faqButtons = document.querySelectorAll('[aria-controls^="faq-"]');
  const faqContents = document.querySelectorAll('[id^="faq-"]');

  // Collapse all FAQ items by default
  faqButtons.forEach((button, index) => {
    button.setAttribute('aria-expanded', 'false');
    faqContents[index].classList.add('hidden');

    button.addEventListener('click', function() {
      const isExpanded = button.getAttribute('aria-expanded') === 'true';

      // Toggle the aria-expanded attribute
      button.setAttribute('aria-expanded', !isExpanded);

      // Toggle the hidden class on the content
      faqContents[index].classList.toggle('hidden', isExpanded);

      // Toggle the hidden class on the icons
      const icons = button.querySelectorAll('svg');
      icons.forEach(icon => icon.classList.toggle('hidden'));
    });
  });
});
